import React, { useContext, useEffect } from "react";
import { graphql, PageProps } from "gatsby";

import { useLanguageContext, LANGUAGE_ACTIONS } from "@/context";
import { useStoryblok } from "@/base/lib/storyblok";
import Layout from "@/components/layouts/Layout";
import NotFound from "@/components/sections/NotFound";
import CommonPostSection from "@/components/sections/CommonPostSection";
import StoryblokComponent from "@/base/utils/connectSbComponent";
import { VideoCard } from "@/components/sections/VideoSection/VideoCard";

const NotFoundPage = ({
  location,
  data,
  pageContext,
}: PageProps<
  NotFoundPageData<
    LayoutTemplate | PostTemplate | PageTemplate | VideoTemplate
  >,
  NotFoundPageContext
>) => {
  const { layout } = data;
  const { lang, languages } = pageContext;
  const { dispatch } = useContext(useLanguageContext);
  const pageStory = useStoryblok<
    LayoutTemplate | PostTemplate | PageTemplate | VideoTemplate
  >(layout, location);
  const { content } = pageStory;

  useEffect(() => {
    dispatch({
      type: LANGUAGE_ACTIONS.setLanguage,
      payload: lang,
    });
    dispatch({
      type: LANGUAGE_ACTIONS.setLanguages,
      payload: languages,
    });
  }, []);

  if (content.component === "common_layout") {
    const { header, footer } = content;
    return (
      <Layout
        header={header && header[0]}
        footer={footer && footer[0]}
        siteName={pageStory.name}
        pathname={location.pathname}
        hash={location.hash}
      >
        <NotFound />
      </Layout>
    );
  } else if (content.component === "common_post") {
    const parsedLayout =
      layout && typeof layout.content === "string"
        ? (JSON.parse(layout.content) as LayoutTemplate)
        : (layout.content as unknown as LayoutTemplate);
    const { footer, header } = parsedLayout;
    return (
      <Layout
        header={header && header[0]}
        footer={footer && footer[0]}
        siteName={pageStory.name}
        pathname={location.pathname}
        hash={location.hash}
      >
        <CommonPostSection
          path={location.href}
          postContent={content}
          postId={pageStory.id}
        />
      </Layout>
    );
  } else if (content.component === "common_page") {
    const parsedLayout =
      layout && typeof layout.content === "string"
        ? (JSON.parse(layout.content) as LayoutTemplate)
        : (layout.content as unknown as LayoutTemplate);
    const { footer, header } = parsedLayout;
    return (
      <Layout
        header={header && header[0]}
        footer={footer && footer[0]}
        siteName={pageStory.name}
        pathname={location.pathname}
        hash={location.hash}
        alternates={[]}
      >
        <StoryblokComponent blok={content} />
      </Layout>
    );
  } else if (content.component === "common_video") {
    const parsedLayout =
      layout && typeof layout.content === "string"
        ? (JSON.parse(layout.content) as LayoutTemplate)
        : (layout.content as unknown as LayoutTemplate);
    const { footer, header } = parsedLayout;
    return (
      <Layout
        header={header && header[0]}
        footer={footer && footer[0]}
        siteName={pageStory.name}
        pathname={location.pathname}
        hash={location.hash}
        alternates={[]}
      >
        <VideoCard videoData={content} storyblokPreview />
      </Layout>
    );
  }

  return (
    <h1>
      THIS STORY WITH TYPE: {content["component"]} CAN NOT BE RENDERED BY 404
      PAGE
    </h1>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query Page404Query($langRegex: String!) {
    layout: storyblokEntry(
      field_component: { eq: "common_layout" }
      full_slug: { regex: $langRegex }
    ) {
      content
    }
  }
`;
